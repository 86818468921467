<template>
  <div style="padding:0px;"
    :style="{ 'background-color': externalBGColor, 'color': textColor }"
  >
  <div class="ticket"
    :style="{ 'background-color': BGColor }"
  >
    <div class="up">
      <v-layout wrap>
        <v-flex xs12 style="margin-bottom: 20px;">
          <h2> {{getTranslation(booking.ActivityName, locale)}} </h2>
        </v-flex>
        <v-flex xs6>
          <v-icon style="margin-top: -5px;"
            :color="textColor"
          >mdi-calendar</v-icon> 
          <span style="font-size: 20px;">
            {{booking.Date.substring(0, 10)}} 
          </span>
        </v-flex>
        <v-flex xs6 style="text-align: right;">
          <v-icon style="margin-top: -5px;"
            :color="textColor"
          >mdi-clock</v-icon> 
          <span style="font-size: 20px;">
            {{ convertTime(booking.Time) }}
          </span>
        </v-flex>
        <v-flex xs12 style="margin-top: 10px">
          <v-icon style="margin-top: -5px;" 
            :color="textColor"
          >mdi-account</v-icon> 
          <span style="font-size: 15px;">
            {{booking.OwnerName}} {{booking.OwnerLastName}}
          </span>
        </v-flex>
        <v-flex xs12 style="margin-top:10px;text-align: right">
          <span>
            {{booking.NumPax}} {{$t('dtouch.pax', locale).toLowerCase()}}
          </span>
        </v-flex>
      </v-layout>
        <p>
        </p>
        <p style="color: red">
          {{booking.Status}}
        </p>
        <v-alert
          v-if="booking.Status !== 'CANCELED' && booking.outdated"
          text
          dense
          outlined
          color="deep-gray"
          icon="mdi-information-variant"
        >
          {{ $t('dtouch.outdatedBooking', locale) }}
        </v-alert>
    </div>
    <div class="line">
      <div class="start" 
        :style="{ 'background-color': externalBGColor }"
      />
        <div class="border" :style="{ 'border-bottom': `2px dashed ${textColor}5D` }" />
      <div class="end" 
        :style="{ 'background-color': externalBGColor }"
      />
    </div>
    <div class="down">
      <center>
        <v-img :src="booking.QR" style="max-width:150px"/>
      </center>
    </div>
  </div>
  </div>
</template>
<script>
import utils from '@/services/utils'
export default {
  props: {
    booking: {
      type: Object,
      required: true,
    },
    locale: {
      type: String,
      required: true,
    },
    externalBGColor: {
      type: String,
      default: 'white',
    },
    BGColor: {
      type: String,
      default: '#DCDCDC',
    },
    textColor: {
      type: String,
      default: '#000000',
    },
  },
  data: () => ({
    qr: null,
  }),
  methods: {
    convertTime (v) {
      return utils.convertTime(v).split(' ').join('')
    },
    getTranslation (v, locale) {
      return utils.getTranslation(v, locale)
    },
  },
}
</script>
<style scoped>
.ticket {
  border-radius: 20px;
  line-height: 1;
}
.ticket .v-icon {
  opacity: 50%;
}
.ticket .up {
  padding: 20px;
}
.ticket .down {
  padding: 10px 0 20px 0 ;
}

.line {
  width: 100% !important;
  height:20px;
  vertical-align: middle;
}
.line .start {
  float: left;
  width: 10px;
  height:20px;
  border-radius: 0 10px 10px 0;
  content: '';
}
.line .border {
  height: 10px;
}
.line .end {
  margin-top: -10px;
  float: right;
  width: 10px;
  height:20px;
  border-radius: 10px 0 0 10px;
  content: '';
}
</style>

